import { T, useTranslate } from '@tolgee/react';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import JamaicaLogo from '../../../../assets/images/jamaicaLogo.png';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { formatDateWithDayJS } from '../../../../common/utils/dateFormat';
import { FeatureFlags } from '../../../../config/featureFlags';
import { useAuthConfigSelector } from '../../../auth/store/authSelectors';
import { useNotificationListActionCreators } from '../../../notificationList/store/notificationListSlice';
import { IncomingNotificationTypesEnum } from '../../enums/incomingNotificationTypesEnum';
import { IncomingNotificationService, NotificationDetailsData } from '../../services/IncomingNotificationService';
import {
  useAllIncomingNotificationSelector,
  useIncomingNotificationIdsSelector
} from '../../store/IncomingNotificationSelectors';
import { useIncomingNotificationCreators } from '../../store/IncomingNotificationSlice';
import './incoming-notification-container.scss';

export const defaultStackNotificationId = '999999999';
const maxNotificationsWithoutStack = 3;

const CLIENT = FeatureFlags.REACT_APP_CLIENT;

const logosMap: { [key: string]: string } = {
  jamaica: JamaicaLogo,
};

const Icon = <img src={logosMap[CLIENT]} alt="Telehealth" />;

const Message = (
  title: string,
  body: string,
  type: IncomingNotificationTypesEnum,
  details: NotificationDetailsData
) => {
  const hasNowLabelTypes = [IncomingNotificationTypesEnum.START_CALL];
  return (
    <div className="notification-body">
      <p className="title">{title}</p>
      {details ? (
        <p className="body">
          {body.replace(
            '#APPOINTMENT_TIME',
            formatDateWithDayJS(details.appointmentStartDatetime, 'h:mm a, MMMM DD, YYYY ')
          )}
        </p>
      ) : (
        <p className="body">{body}</p>
      )}
      {hasNowLabelTypes.includes(type) && (
        <span className="now-label">
          <T keyName="features.IncomingNotificationContainer.nowLabel" />
        </span>
      )}
    </div>
  );
};

export const IncomingNotificationContainer = () => {
  const navigate: NavigateFunction = useNavigate();
  const { t } = useTranslate();
  const defaultStackNotification = {
    id: defaultStackNotificationId,
    title: t('features.IncomingNotificationContainer.notification.title'),
    body: t('features.IncomingNotificationContainer.notification.body'),
    type: IncomingNotificationTypesEnum.STACK
  };
  const { user } = useAuthConfigSelector();
  const isAdmin = user?.role?.id === UserRoleEnum.Admin;
  const isProvider = user?.role?.id === UserRoleEnum.Provider;
  const isPeerNavigator = user?.role?.id === UserRoleEnum.PeerNavigator;
  const incommingNotificationActions = useIncomingNotificationCreators();
  const notificationsListActions = useNotificationListActionCreators();
  const incomingNotifications = useAllIncomingNotificationSelector();
  const incomingNotificationIds = useIncomingNotificationIdsSelector();
  const hasStack = incomingNotificationIds.filter((id) => id === defaultStackNotificationId);
  const showStack =
    (isAdmin || isProvider || isPeerNavigator) &&
    incomingNotificationIds.length <= maxNotificationsWithoutStack &&
    !hasStack.length;
  const [currentNotificationsInView, setCurrentNotificationsInView] = useState<string[]>([]);

  const [api, contextHolder] = notification.useNotification({
    stack: showStack
      ? {
        threshold: maxNotificationsWithoutStack
      }
      : false
  });

  useEffect(() => {
    const showStackNotification = !showStack && hasStack.length < 1 && incomingNotificationIds.flat().length;

    if (showStackNotification) {
      IncomingNotificationService.destroyAll(api, incomingNotificationIds);
      incommingNotificationActions.addOneAsStack(defaultStackNotification);
    }
  }, [showStack, incomingNotificationIds, hasStack.length]);

  useEffect(() => {
    const notificationsToShow = !showStack
      ? incomingNotifications.filter(
        ({ id }) => id === defaultStackNotificationId && !currentNotificationsInView.includes(id)
      )
      : incomingNotifications.filter(({ id }) => !currentNotificationsInView.includes(id!));

    IncomingNotificationService.showNotifications({
      notifications: notificationsToShow,
      api,
      navigate,
      icon: Icon,
      message: Message,
      incommingNotificationActions,
      notificationsListActions
    });
    setCurrentNotificationsInView(incomingNotifications.map(({ id }) => id!));
  }, [incomingNotifications, showStack]);

  return <>{contextHolder}</>;
};
