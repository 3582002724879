import { Locale } from 'antd/es/locale';
import enLocale from 'antd/locale/en_US';
import { Lang, Locales } from './constants';
import en from 'antd/es/locale/en_GB';

export const defaultLocale = 'en';

export const updateLocaleData = {
  [defaultLocale]: {
    weekStart: 1
  },
};

export const antDLocales: { [key: string]: Locale } = {
  [defaultLocale]: enLocale,
};

export const localesMap: {[key in Locales]: Lang} = {
  [Locales['en-Jm']]: Lang.en,
};

export const LIST_LANGUAGES: {[key: string]: Locale} = {
  [Locales['en-Jm']]: en
};
