import { FormatIcu } from '@tolgee/format-icu';
import { FormatSimple, LanguageStorage, Tolgee, TolgeeProvider } from '@tolgee/react';
import { Provider } from 'react-redux';
import '../assets/styles/app.scss';
import AppRoutes from '../common/providers/AppRoutes';
import { AppointmentChatProvider } from '../common/providers/AppointmentChatProvider';
import { HelmetProvider } from '../common/providers/HelmetProvider';
import { SocketProvider } from '../common/providers/SocketProvider';
import ThemeProvider from '../common/providers/ThemeProvider';
import { injectStore } from '../common/services/http/interceptors';
import { authActions } from '../features/auth/store/authSlice';
import { LanguageChangeProvider } from '../localization/providers/LanguageChangeProvider';
import { store } from './rootReducer';

injectStore(store);
store.getState().auth.authorized && store.dispatch(authActions.getUser());
const availableLanguages = process.env.REACT_APP_LOCALES?.split(',') || ([] as string[]);

export const tolgee = Tolgee()
  .use(LanguageStorage())
  .use(FormatSimple())
  .use(FormatIcu())
  .init({
    defaultLanguage: availableLanguages[0] || 'en-Jm',
    staticData: {
      'en-Jm': () => import('../localization/translations/en-Jm.json'),
    }
  });

function App() {
  return (
    <TolgeeProvider tolgee={tolgee}>
      <HelmetProvider />
      <Provider store={store}>
        <LanguageChangeProvider />
        <ThemeProvider>
          <SocketProvider />
          <AppRoutes />
          <AppointmentChatProvider />
        </ThemeProvider>
      </Provider>
    </TolgeeProvider>
  );
}

export default App;
