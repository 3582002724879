import classNames from 'classnames';
import { FC } from 'react';
import { ReactComponent as Chat } from '../../../../assets/icons/chat.svg';
import { ReactComponent as Audio } from '../../../../assets/icons/phone.svg';
import { ReactComponent as VideoCam } from '../../../../assets/icons/videocam.svg';
import { AppointmentTypeEnum } from '../../../../common/enums/appointmentTypeEnum';
import style from './apointment-type.module.scss';

interface Props {
  type: AppointmentTypeEnum;
  isPeerNavigatorAppointment: boolean;
  disabled?: boolean;
}

export const AppointmentType: FC<Props> = ({ type, isPeerNavigatorAppointment, disabled = false }) => {
  const classes = classNames(
    style.typeImage,
    disabled ? style.disabled : '',
    isPeerNavigatorAppointment ? style.peerNavigator : ''
  );

  return (
    <>
      {type === AppointmentTypeEnum.video && <VideoCam className={classes} />}
      {type === AppointmentTypeEnum.audio && <Audio className={classes} />}
      {type === AppointmentTypeEnum.text && <Chat className={classes} />}
    </>
  );
};
