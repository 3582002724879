import {
  ControlBar,
  DeviceLabelTriggerStatus,
  LocalVideo,
  useAudioVideo,
  useDeviceLabelTriggerStatus,
  useLocalVideo,
  useMeetingManager,
  useRosterState,
  useToggleLocalMute
} from 'amazon-chime-sdk-component-library-react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppointmentTypeEnum } from '../../../../common/enums/appointmentTypeEnum';
import { Appointment } from '../../../../features/appointment/interfaces/appointment';
import useMeetingDetailsModal from '../../hooks/useAppointmentJoinMeetingDetails';
import { ControlBarButton } from '../AppointmentJoinControlButton/AppointmentJoinControlButton';
import { MeetingNotificationBar } from '../AppointmentJoinNotificationBar/AppointmentJoinNotificationBar';
import { AppointmentModalPermission } from '../AppointmentModalPermission/AppointmentModalPermission';
import Camera from '../svg/Camera';
import Microphone from '../svg/Microphone';
import Phone from '../svg/Phone';
import { AttendeeTiles } from './AttendeeTiles';
import styles from './appointment-join-page-content.module.scss';

import type { AudioVideoObserver, DeviceChangeObserver } from 'amazon-chime-sdk-js';

export const AppointmentJoinPageContent = ({ appointment }: { appointment: Appointment }) => {
  const navigate = useNavigate();
  const { roster } = useRosterState();

  const audioVideo = useAudioVideo();

  const meetingManager = useMeetingManager();
  const status: DeviceLabelTriggerStatus = useDeviceLabelTriggerStatus();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const [isModalHaveBeenShown, setModalHaveBeenShown] = useState(false);
  const { MeetingDetailsModal, showMeetingDetailsModal } = useMeetingDetailsModal(appointment);
  const [defaultMediaConfig, setDefaultMediaConfig] = useState({
    isMicrophoneMuted: false,
    isVideoDisabled: true
  });
  const [showMicrophoneAndCameraAccessModal, setShowMicrophoneAndCameraAccessModal] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);

  const deviceChangeObserver: DeviceChangeObserver = {
    audioInputsChanged: (freshDevice: MediaDeviceInfo[]) => {
      const currentInputDevice = freshDevice.find(({deviceId}) => deviceId === 'default');
      if (currentInputDevice) {
        audioVideo?.startAudioInput({
          deviceId: currentInputDevice.deviceId,
          groupId: currentInputDevice.groupId,
        }).then((stream: MediaStream | undefined) => {
          stream && audioVideo.mixIntoAudioInput(stream);
        });
      }
    }
  };

  useEffect(() => {
    audioVideo?.addDeviceChangeObserver(deviceChangeObserver);

    return () => {
      audioVideo?.removeDeviceChangeObserver(deviceChangeObserver);
    };
  }, [audioVideo]);

  const setMessage = (text: string) => {
    setMessages((s) => [text, ...s.filter((m) => m !== text)]);
  };

  const deleteMessage = (text: string) => {
    setMessages((s) => s.filter((m) => m !== text));
  };

  useEffect(() => {
    if (!isModalHaveBeenShown) {
      showMeetingDetailsModal();
      setModalHaveBeenShown(true);
    }
  });

  useEffect(() => {
    if (audioVideo) {
      const badConnectionMessage = 'Bad Connection';
      const observer: AudioVideoObserver = {
        audioVideoDidStartConnecting: (reconnecting: boolean) => {
          if (reconnecting) {
            window.location.reload();
          }
        },
        // https://github.com/aws/amazon-chime-sdk-js/issues/2676
        connectionDidBecomePoor: () => {
          setMessage(badConnectionMessage);
        },
        connectionDidBecomeGood: () => {
          deleteMessage(badConnectionMessage);
        },
        connectionDidSuggestStopVideo: () => {
          setMessage(badConnectionMessage);
        }
      };
      // By default video is off and audio is enabled; Let's change settings based on config
      if (defaultMediaConfig.isMicrophoneMuted) toggleMute();
      if (!defaultMediaConfig.isVideoDisabled) toggleVideo();
      audioVideo.addObserver(observer);
    }
  }, [audioVideo]);

  const microphoneButtonProps = {
    icon: <Microphone fill="#CCCCCC" disabled={muted} />,
    onClick: async () => {
      try {
        if (status === DeviceLabelTriggerStatus.DENIED) {
          const stream: MediaStream = await navigator.mediaDevices.getUserMedia({
            audio: true
          });
          await audioVideo?.startAudioInput(stream);
        }
        toggleMute();
      } catch (err) {
        // Error will be thrown in case when access to media is denied.
        // Let's show the pop-up in such case
        setShowMicrophoneAndCameraAccessModal(true);
      }
    }
  };

  const cameraButtonProps = {
    icon: <Camera fill="#CCCCCC" disabled={!isVideoEnabled} />,
    onClick: async () => {
      try {
        if (
          (status === DeviceLabelTriggerStatus.DENIED || status === DeviceLabelTriggerStatus.UNTRIGGERED)
          && !isVideoEnabled
        ) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
          });
          await audioVideo?.startVideoInput(stream);
        }
        toggleVideo();
      } catch (err) {
        // Error will be thrown in case when access to media is denied.
        // Let's show the pop-up in such case
        setShowMicrophoneAndCameraAccessModal(true);
      }
    }
  };

  const hangUpButtonProps = {
    icon: <Phone />,
    onClick: () => {
      audioVideo?.stop();
      meetingManager.leave();
      navigate(-1);
    }
  };

  const { remoteAttendees, attendees } = useMemo(() => {
    const users = Object.values(roster);
    const waitingMessage = 'Waiting for others to join';
    if (users.length === 1) {
      setMessage(waitingMessage);
    } else {
      deleteMessage(waitingMessage);
    }
    return {
      attendees: users,
      remoteAttendees: users.filter(
        (attendee) => attendee.chimeAttendeeId !== meetingManager.meetingSessionConfiguration?.credentials?.attendeeId
      )
    };
  }, [roster]);

  return (
    <div className={styles.wrap}>
      <AppointmentModalPermission
        appointment={ appointment }
        showMicrophoneAndCameraAccessModal={ showMicrophoneAndCameraAccessModal }
        setShowMicrophoneAndCameraAccessModal={ setShowMicrophoneAndCameraAccessModal } />
      <MeetingDetailsModal setMediaConfig={setDefaultMediaConfig} />
      <MeetingNotificationBar message={messages[0]} />
      <AttendeeTiles appointment={appointment} attendees={remoteAttendees} />

      {attendees.length && audioVideo ? (
        <>
          <div className={styles.localVideo}>
            <LocalVideo />
          </div>
          <ControlBar showLabels={false} layout="bottom" responsive={true}>
            {appointment?.type === AppointmentTypeEnum.video && (
              <ControlBarButton {...cameraButtonProps}></ControlBarButton>
            )}
            <ControlBarButton {...microphoneButtonProps}></ControlBarButton>
            <ControlBarButton {...hangUpButtonProps}></ControlBarButton>
          </ControlBar>
        </>
      ) : null}
    </div>
  );
};
