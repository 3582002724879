import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC } from 'react';
import { AppointmentTypeEnum } from '../../../../common/enums/appointmentTypeEnum';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { useActionResultModals } from '../../../../common/hooks/useActionResultModals';
import { ConfirmAsEnum, useConfirm } from '../../../../common/hooks/useConfirm';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { formatDateString } from '../../../../common/utils/dateFormat';
import { AppointmentCreateSelectedUser } from '../../../appointmentCreate/components';
import { ClinicServiceEnum } from '../../../appointmentCreate/enums/clinicService';
import { useUserSelector } from '../../../auth/store/authSelectors';
import { AppointmentStatusEnum } from '../../enums/appointmentEnums';
import { Appointment } from '../../interfaces/appointment';
import { getAttendeeByRole } from '../../utils/helpers';
import { AppointmentAddToCalendarAction } from '../AppointmentActions/AppointmentAddToCalendarAction';
import { AppointmentCancelAction } from '../AppointmentActions/AppointmentCancelAction';
import { AppointmentConfirmAction } from '../AppointmentActions/AppointmentConfirmAction';
import { AppointmentJoinAction } from '../AppointmentActions/AppointmentJoinAction';
import { AppointmentComment } from '../AppointmentComment/AppointmentComment';
import { AppointmentRecurrenceDescription } from '../AppointmentRecurrence/AppointmentRecurrenceDescription';
import { AppointmentStatus } from '../AppointmentStatus/AppointmentStatus';
import { AppointmentType } from '../AppointmentType/AppointmentType';
import style from './appointment-details.module.scss';

export const AppointmentDetails: FC<{ appointment: Appointment; refreshPage: () => void }> = ({
  appointment,
  refreshPage
}) => {
  const { t } = useTranslate();
  const { SuccessModal, ErrorModal, onActionResponse } = useActionResultModals(refreshPage);
  const user = useUserSelector();
  const { isDesktop } = useWindowSizeType();

  const patient =
    getAttendeeByRole(appointment.attendees, UserRoleEnum.Tester) ||
    getAttendeeByRole(appointment.attendees, UserRoleEnum.User);
  const provider =
    getAttendeeByRole(appointment.attendees, UserRoleEnum.PeerNavigator) ||
    getAttendeeByRole(appointment.attendees, UserRoleEnum.Provider) ||
    getAttendeeByRole(appointment.attendees, UserRoleEnum.Admin);

  const isAdmin = user?.role.id === UserRoleEnum.Admin;
  const showProvider = !!provider && isAdmin && provider.userId !== user?.id;
  const isTesterAppointment = provider?.clinicServiceType === ClinicServiceEnum.PeerNavigator;

  const shouldConfirm = useConfirm(appointment);
  const isActionsVisible = dayjs(appointment.endDatetime).isAfter(new Date());
  const isJoinBtnActive =
    appointment.status === AppointmentStatusEnum.CONFIRMED || appointment.status === AppointmentStatusEnum.ACTIVE;

  const joinButtonTitles: Record<AppointmentTypeEnum, string> = {
    [AppointmentTypeEnum.video]: t('features.AppointmentDetails.joinVideoBtn.label'),
    [AppointmentTypeEnum.audio]: t('features.AppointmentDetails.joinAudioBtn.label'),
    [AppointmentTypeEnum.text]: t('features.AppointmentDetails.joinChatBtn.label')
  };

  const AppointmentDateAndType = () => {
    return (
      <div className={style.date}>
        <AppointmentType type={appointment.type} isPeerNavigatorAppointment={isTesterAppointment} />
        {formatDateString(appointment.startDatetime, appointment.endDatetime)}
      </div>
    );
  };

  return (
    <div>
      <div className={style.topLine}>
        <p className={style.appointmentId}>
          {t('features.AppointmentDetails.appointmentId.label')} {appointment.id}
        </p>
        <AppointmentStatus status={appointment.status} isPeerNavigatorAppointment={isTesterAppointment} />
      </div>
      {!isDesktop && (
        <>
          <AppointmentDateAndType />
          <AppointmentRecurrenceDescription seriesConfig={appointment.seriesConfig} />
          {appointment.comment && (
            <AppointmentComment
              ownerRole={appointment.owner?.role}
              text={appointment.comment}
              isTester={!!appointment.owner.ssiId}
            />
          )}
          {showProvider && (
            <p className={style.participantsTitle}>{t('features.AppointmentDetails.participants.label')}</p>
          )}
        </>
      )}
      <div className={style.card}>
        {isDesktop && (
          <div className={style.leftSide}>
            <AppointmentDateAndType />
            <AppointmentRecurrenceDescription seriesConfig={appointment.seriesConfig} />
            {appointment.comment && (
              <AppointmentComment ownerRole={appointment.owner.role} text={appointment.comment} />
            )}
          </div>
        )}
        <div className={style.rightSide}>
          {showProvider && <AppointmentCreateSelectedUser user={provider} isAdmin />}
          {patient ? (
            <AppointmentCreateSelectedUser user={patient} isAdmin={isAdmin} isPatient={true} />
          ) : (
            t('features.AppointmentDetails.noPatient.label')
          )}
        </div>
      </div>
      {isActionsVisible && (
        <div className={style.actions}>
          <AppointmentAddToCalendarAction appointment={appointment} class={style.button} />
          {isJoinBtnActive && (
            <AppointmentJoinAction
              appointment={appointment}
              title={joinButtonTitles[appointment.type]}
              class={classNames(style.button, 'buttonPrimary')}
            />
          )}
          {shouldConfirm === ConfirmAsEnum.attendee ? (
            <AppointmentConfirmAction
              appointment={appointment}
              title={t('features.AppointmentDetails.confirmBtn.label')}
              class={style.button}
              onActionResponse={onActionResponse}
            />
          ) : shouldConfirm === ConfirmAsEnum.provider ? (
            <AppointmentConfirmAction
              appointment={appointment}
              title={t('common.confirmAsProviderBtn.label')}
              onActionResponse={onActionResponse}
              class={`${style.button} buttonSuccessOutlined`}
              userId={getAttendeeByRole(appointment.attendees, UserRoleEnum.Provider)?.userId}
            />
          ) : shouldConfirm === ConfirmAsEnum.peerNavigator ? (
            <AppointmentConfirmAction
              appointment={appointment}
              title={t('common.confirmAsNavigatorBtn.label')}
              onActionResponse={onActionResponse}
              class={`${style.button} buttonSuccessOutlined`}
              userId={getAttendeeByRole(appointment.attendees, UserRoleEnum.PeerNavigator)?.userId}
            />
          ) : shouldConfirm === ConfirmAsEnum.patient ? (
            <AppointmentConfirmAction
              appointment={appointment}
              title={t('common.confirmAsPatientBtn.label')}
              onActionResponse={onActionResponse}
              class={`${style.button} buttonSuccessOutlined`}
              userId={getAttendeeByRole(appointment.attendees, UserRoleEnum.User)?.userId}
            />
          ) : null}
          <AppointmentCancelAction
            title={t('features.AppointmentDetails.cancelBtn.label')}
            onActionResponse={onActionResponse}
            class={`${style.button} buttonCancel`}
            appointment={appointment}
          />
        </div>
      )}
      <SuccessModal />
      <ErrorModal type="error" />
    </div>
  );
};
