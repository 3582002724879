export enum RegionsEnum {
  Clarendon = 'Clarendon',
  Hanover = 'Hanover',
  Kingston = 'Kingston',
  Manchester = 'Manchester',
  Portland = 'Portland',
  StAndrew = 'St. Andrew',
  StAnn = 'St. Ann',
  StCatherine = 'St. Catherine',
  StElizabeth = 'St. Elizabeth',
  StJames = 'St. James',
  StMary = 'St. Mary',
  StThomas = 'St. Thomas',
  Trelawny = 'Trelawny',
  Westmoreland = 'Westmoreland',
}

export const enumsRegions =  {
  'jamaica': RegionsEnum,
};

export enum Locale {
  EN = 'en-us',
}
