import { GlobalStyles, MeetingProvider, darkTheme } from 'amazon-chime-sdk-component-library-react';
import { useLocation } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { AppointmentJoinPageContent } from '../../features/appointmentJoin/components';

export const AppointmentJoinPage = () => {
  const location = useLocation();
  const appointment = location.state?.appointment;

  return (
    <ThemeProvider theme={darkTheme}>
      <GlobalStyles />
      <MeetingProvider>
        <AppointmentJoinPageContent appointment={appointment} />
      </MeetingProvider>
    </ThemeProvider>
  );
};
