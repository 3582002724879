import { useEffect, useState } from 'react';
import { enumsRegions } from '../../localization/data';

export const useGetListOfProvince = () => {
  const [listLocations, setListLocations] = useState<{ value: string; label: string }[]>([]);
  useEffect(() => {
    const getListLocation = async () => {
      const locations: string[] = Object.values(enumsRegions.jamaica);
      const locationsArr = locations.map(item => ({
        value: item,
        label: item
      }));
      setListLocations(locationsArr);
    };
    getListLocation();
  }, []);
  return {
    listLocations
  };
};
