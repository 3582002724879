
export const LANGS = process.env.REACT_APP_LOCALES?.split(',') || ([] as string[]);

export const localesTranslationsMap: Record<string, string> = {
  'en-Jm': 'common.lang.en-Jm',
};

export enum Lang {
  en = 'en',
}

export enum Locales {
  'en-Jm' = 'en-Jm',
}

