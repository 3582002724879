/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable indent */
import { T, useTranslate } from '@tolgee/react';
import { Button, Collapse, Table } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down.svg';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { formatDateWithDayJS, transformToSameDate } from '../../../../common/utils/dateFormat';
import { useAuthConfigSelector } from '../../../auth/store/authSelectors';
import useConfirmModal from '../../../ui/Modal/useConfirmModal';
import { Schedule, Slot } from '../../interfaces/schedule';
import {
  AvailabilityDaoService,
  GetScheduleResponse,
  UpdateScheduleParams
} from '../../services/AvailabilityDaoService';
import { AvailabilityAddNewTimeSlotBtn } from '../AvailabilityAddNewTimeSlotBtn/AvailabilityAddNewTimeSlotBtn';
import { AvailabilityCheckboxes } from '../AvailabilityCheckboxes/AvailabilityCheckboxes';
import { AvailabilityContactMethodsCheckboxes } from '../AvailabilityContactMethodsCheckboxes/AvailabilityContactMethodsCheckboxes';
import { AvailabilityDayCheckbox } from '../AvailabilityDayCheckbox/AvailabilityDayCheckbox';
import { AvailabilitySlotActions } from '../AvailabilitySlotActions/AvailabilitySlotActions';
import style from './availability-schedule.module.scss';

const { Panel } = Collapse;

interface AvailabilityProps {
  providerId?: string;
}

export const CONTACT_METHOD_ERROR = (<T keyName="features.Availability.errContactMethod" />) as unknown as string;
const CHECK_TIME_ERROR = (<T keyName="features.Availability.errCheckTime" />) as unknown as string;
const EMPTY_TIME_ERROR = (<T keyName="features.Availability.errEmptyTime" />) as unknown as string;
const ALREADY_HAVE_TIME_ERROR = (<T keyName="features.Availability.errDuplicatedSlot" />) as unknown as string;

export const Availability: React.FC<AvailabilityProps> = (props) => {
  const { t } = useTranslate();
  const { user } = useAuthConfigSelector();
  const userId = user?.id;

  const { isMobile } = useWindowSizeType();

  const [data, setData] = useState<GetScheduleResponse>({ schedule: [] });
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const { ConfirmModal } = useConfirmModal(isDataChanged);

  const fetchSchedule = async () => {
    const res = await AvailabilityDaoService.getSchedule({ providerId: props.providerId });
    setData(res);
  };

  useEffect(() => {
    fetchSchedule();
  }, []);

  const handleDayChange = (dayOfTheWeek: number, checked: boolean) => {
    setData((prevData) => ({
      ...prevData,
      schedule: prevData?.schedule.map((day) =>
        day.dayOfTheWeek === dayOfTheWeek ? { ...day, enabled: checked } : day
      )
    }));
    setIsDataChanged(true);
  };

  const handleAddSlot = (dayOfTheWeek: number) => {
    setData((prevData) => ({
      ...prevData,
      schedule: prevData.schedule.map((day) =>
        day.dayOfTheWeek === dayOfTheWeek
          ? {
              ...day,
              slots: [
                ...(day.slots || []),
                {
                  startTime: '',
                  endTime: '',
                  audio: false,
                  video: true,
                  chat: false,
                  deleted: false
                }
              ]
            }
          : { ...day, slots: day.slots || [] }
      )
    }));
    setIsDataChanged(true);
  };

  const handleRemoveSlot = (dayOfTheWeek: number, index: number) => {
    setData((prevData) => ({
      ...prevData,
      schedule: prevData.schedule.map((day) =>
        day.dayOfTheWeek === dayOfTheWeek
          ? {
              ...day,
              slots: (day.slots || []).map((slot, i) => (i === index ? { ...slot, deleted: true } : slot))
            }
          : day
      )
    }));
    setIsDataChanged(true);
  };

  const handleUndoRemoveSlot = (dayOfTheWeek: number, index: number) => {
    setData((prevData) => ({
      ...prevData,
      schedule: prevData.schedule.map((day) =>
        day.dayOfTheWeek === dayOfTheWeek
          ? {
              ...day,
              slots: (day.slots || []).map((slot, i) => (i === index ? { ...slot, deleted: false } : slot))
            }
          : day
      )
    }));
    setIsDataChanged(true);
  };

  const handleTimeChange = (dayOfTheWeek: number, index: number, field: 'startTime' | 'endTime', value: string) => {
    setData((prevData) => ({
      ...prevData,
      schedule: prevData.schedule.map((day) =>
        day.dayOfTheWeek === dayOfTheWeek
          ? {
              ...day,
              slots: (day.slots || []).map((slot, i) => (i === index ? { ...slot, [field]: value } : slot))
            }
          : day
      )
    }));
    setIsDataChanged(true);
  };

  const handleMethodChange = (
    dayOfTheWeek: number,
    index: number,
    field: 'audio' | 'video' | 'chat',
    checked: boolean
  ) => {
    setData((prevData) => ({
      ...prevData,
      schedule: prevData.schedule.map((day) =>
        day.dayOfTheWeek === dayOfTheWeek
          ? {
              ...day,
              slots: (day.slots || []).map((slot, i) => (i === index ? { ...slot, [field]: checked } : slot))
            }
          : day
      )
    }));
    setIsDataChanged(true);
  };

  const prepareData = (data: GetScheduleResponse): GetScheduleResponse => {
    return {
      ...data,
      schedule: data.schedule.map((day) => {
        const newDay = { ...day };
        const slots = newDay.slots?.filter((slot) => !slot.deleted).map((slot) => ({ ...slot })) || [];

        return { ...newDay, slots };
      })
    };
  };

  const cleanErrorsBeforeValidation = (data: any): GetScheduleResponse => {
    for (const prop in data) {
      if (data[prop]?.error !== undefined) {
        data[prop].error = undefined;
      }

      if (typeof data[prop] === 'object') {
        data[prop] = cleanErrorsBeforeValidation(data[prop]);
      }
    }

    return data;
  };

  const validateData = (data: GetScheduleResponse): boolean => {
    cleanErrorsBeforeValidation(data);
    let hasErrors = false;

    data.schedule.forEach((day) => {
      if (!day.enabled) {
        day.slots = day.slots?.filter(
          (slot) => !slot.error && slot.startTime && slot.endTime && (slot.video || slot.audio || slot.chat)
        );
        return;
      }

      day.slots?.forEach((slot1, i) => {
        if (!slot1.video && !slot1.audio && !slot1.chat) {
          slot1.error = CONTACT_METHOD_ERROR;
          hasErrors = true;
          return;
        }

        if (!slot1.startTime || !slot1.endTime) {
          slot1.error = EMPTY_TIME_ERROR;
          hasErrors = true;
          return;
        }

        const start1 = transformToSameDate(dayjs(slot1.startTime));
        const end1 = transformToSameDate(dayjs(slot1.endTime));

        if (start1.isAfter(end1)) {
          slot1.error = CHECK_TIME_ERROR;
          hasErrors = true;
          return;
        }

        day.slots?.slice(i + 1).forEach((slot2) => {
          if (!slot2.startTime || !slot2.endTime) {
            return;
          }

          const start2 = transformToSameDate(dayjs(slot2.startTime).add(1, 'second'));
          const end2 = transformToSameDate(dayjs(slot2.endTime));

          const isSameTime = start1.isSame(start2) && end1.isSame(end2);
          const overlapsTime = start1.isBefore(end2) && end1.isAfter(start2);

          if (isSameTime || overlapsTime) {
            slot2.error = ALREADY_HAVE_TIME_ERROR;
            hasErrors = true;
          }
        });
      });
    });

    return !hasErrors;
  };

  const handleSave = async () => {
    const preparedData = prepareData(data);
    const isValid = validateData(preparedData);

    if (isValid) {
      const updateScheduleParams: UpdateScheduleParams = {
        schedule: preparedData.schedule,
        providerId: Number(props.providerId) || userId!,
        providerTimezoneOffset: -new Date().getTimezoneOffset()
      };
      setIsDataChanged(false);
      setIsSaveLoading(true);
      await AvailabilityDaoService.updateSchedule(updateScheduleParams);
      fetchSchedule();
      setIsSaveLoading(false);
    }
    setData(preparedData);
  };

  const columns = [
    {
      title: t('features.Availability.dayColumn.title'),
      dataIndex: 'dayOfTheWeek',
      key: 'dayOfTheWeek',
      className: 'slot-day',
      render: (dayOfTheWeek: number, record: Schedule) => (
        <AvailabilityDayCheckbox record={record} dayOfTheWeek={dayOfTheWeek} handleDayChange={handleDayChange} />
      )
    },
    {
      title: t('features.Availability.fromColumn.title'),
      dataIndex: 'slots',
      key: 'slots',
      className: 'slot-from',
      render: (slots: Slot[], record: Schedule) => {
        return (
          <>
            {slots?.map((slot, index) => (
              <AvailabilityCheckboxes
                key={index}
                slot={slot}
                record={record}
                handleTimeChange={handleTimeChange}
                index={index}
              />
            ))}
            {record.enabled && (
              <AvailabilityAddNewTimeSlotBtn handleAddSlot={() => handleAddSlot(record.dayOfTheWeek)} />
            )}
          </>
        );
      }
    },
    {
      title: t('features.Availability.contactMethodColumn.title'),
      dataIndex: 'slots',
      key: 'methods',
      className: 'slot-methods',
      render: (slots: Slot[], record: Schedule) => (
        <>
          {slots?.map((slot, index) => (
            <div key={index} className={`slot-methods-container ${slot.deleted ? 'slot-deleted' : ''}`}>
              <AvailabilityContactMethodsCheckboxes
                record={record}
                slot={slot}
                handleMethodChange={handleMethodChange}
                index={index}
              />
              <AvailabilitySlotActions
                slot={slot}
                record={record}
                handleRemoveSlot={handleRemoveSlot}
                handleUndoRemoveSlot={handleUndoRemoveSlot}
                index={index}
              />
            </div>
          ))}
        </>
      )
    }
  ];

  const mobileData = [
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
      className: 'data',
      render: (slots: Slot[], record: Schedule) => {
        return (
          <div className={style.mobileContent}>
            {slots?.map((slot, index) => (
              <div className={style.item} key={index}>
                <AvailabilitySlotActions
                  slot={slot}
                  record={record}
                  handleRemoveSlot={handleRemoveSlot}
                  handleUndoRemoveSlot={handleUndoRemoveSlot}
                  index={index}
                />
                <p className={style.label}>{t('features.Availability.fromColumn.title')}</p>
                <AvailabilityCheckboxes
                  key={index}
                  slot={slot}
                  record={record}
                  handleTimeChange={handleTimeChange}
                  index={index}
                />
                <p className={style.label}>{t('features.Availability.contactMethodColumn.title')}</p>
                <AvailabilityContactMethodsCheckboxes
                  record={record}
                  slot={slot}
                  handleMethodChange={handleMethodChange}
                  index={index}
                />
              </div>
            ))}
            {record.enabled && (
              <AvailabilityAddNewTimeSlotBtn handleAddSlot={() => handleAddSlot(record.dayOfTheWeek)} />
            )}
          </div>
        );
      }
    }
  ];

  const SaveButton = () => {
    return (
      <Button type="primary" className={style.button} onClick={handleSave} disabled={isSaveLoading || !isDataChanged}>
        {t('features.Availability.saveBtn.text')}
      </Button>
    );
  };

  return (
    <>
      {!isMobile && <SaveButton />}
      <div className={style.scheduleTopLine}>
        <p className={style.blockTitle}>{t('features.Availability.title')}</p>
        {data.updatedBy && data.updatedAt && (
          <p className={style.updatedBy}>
            {t('features.Availability.updatedBy.text', {
              userName: `${data.updatedBy.firstName} ${data.updatedBy.lastName}`,
              date: formatDateWithDayJS(data.updatedAt, 'LLL')
            })}
          </p>
        )}
      </div>
      {!isMobile && (
        <Table
          className="availability-table"
          dataSource={data.schedule}
          columns={columns}
          pagination={false}
          locale={{ emptyText: t('common.noData.label') }}
          rowKey={(record) => record.dayOfTheWeek}
          rowClassName={(record) => (record.enabled ? '' : style.rowDisabled)}
        />
      )}
      {isMobile && (
        <>
          <Collapse
            className="availability-collapse"
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <ArrowDown className={classNames(style.arrowDown, { [style.active]: isActive })} />
            )}
          >
            {data.schedule.map((day) => {
              // @ts-expect-error
              const header = columns[0].render(day.dayOfTheWeek, day);
              const collapsible = day.enabled || (day.slots && day.slots.length > 0);

              return (
                <Panel
                  header={header}
                  collapsible={collapsible ? 'header' : 'disabled'}
                  key={day.dayOfTheWeek}
                  className={classNames({ 'panel-disabled': !day.enabled, 'disable-collapse': !collapsible })}
                >
                  <div className="mobile-data">{mobileData[0].render(day.slots as Slot[], day)}</div>
                </Panel>
              );
            })}
          </Collapse>
          <SaveButton />
        </>
      )}
      <ConfirmModal onOk={() => {}} />
    </>
  );
};
