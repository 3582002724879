import { SortDirections } from '../enums/sortDirectionsEnum';

export const baseUrl = process.env.REACT_APP_API_URL;
export const iAmReadyApiUrl = process.env.REACT_APP_I_AM_READY_API_URL ?? '';
export const s3BucketUrl = process.env.REACT_APP_S3_BUCKET_URL;
export const currentTheme = process.env.REACT_APP_THEME;
export const tokenExpiresLimitInMS = 24 * 60 * 60 * 1000; // 24h
export const customDateFormat = 'MMM DD, YYYY';
export const dateTimeFormat = 'MMM D, YYYY  hh:mm a';
export const customTimeFormat = 'h:mm a';
export const second = 1000;
export const minute = second * 60;

// at least 1 digit and 1 special symbol
export const specialSymbolPattern = /(?=.*[0-9])(?=.*[-!@#$%^&*()_=+{}[\]|:;"'<,>.?/~`\\])/;

export const LATIN_NUMBERS_WITH_SPECIFIC_SYMBOLS_REGEX = /^[A-Za-z0-9.,!?@£$%^’“&*()_\-+=§±`~:;'"\\|/'><*\s]*$/;// with symbols "?'"“’

export const ascDescSortDirections = [SortDirections.Ascend, SortDirections.Descend, SortDirections.Ascend];
